import "@mantine/core/styles.css";
import "@mantine/core/styles.layer.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/nprogress/styles.css";
import "mantine-datatable/styles.layer.css";
import "~/layout.css";
import "~/tailwind.css"; // Correct import without assigning to a variable

import { ColorSchemeScript, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { NavigationProgress, nprogress } from "@mantine/nprogress";
import { cssBundleHref } from "@remix-run/css-bundle";
import {
  type LinksFunction,
  type LoaderFunctionArgs,
  json,
} from "@remix-run/node";
import {
  Links,
  Meta,
  MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigation,
} from "@remix-run/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";
import { AuthenticityTokenProvider } from "remix-utils/csrf/react";
import { csrf } from "./utils/csrf.server";

export const links: LinksFunction = () => [
  ...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : []),
];

export const meta: MetaFunction = () => [
  {
    charset: "utf-8",
  },
  {
    title: "Trustmedis Hub",
  },
  {
    name: "viewport",
    content: "width=device-width,initial-scale=1",
  },
];

export async function loader({ request }: LoaderFunctionArgs) {
  const { pathname } = new URL(request.url);
  const BASE_API = process.env.BASE_API ?? "";
  const [token, cookieHeader] = await csrf.commitToken();
  return json(
    {
      csrf: token,
      currentPathname: pathname,
      BASE_API: BASE_API,
    },
    {
      headers: {
        "set-cookie": cookieHeader ?? "",
      },
    }
  );
}

const queryClient = new QueryClient();

export default function App() {
  const { BASE_API, csrf } = useLoaderData<typeof loader>();
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state !== "idle") {
      nprogress.start();
    } else {
      nprogress.complete();
    }
  }, [navigation.state]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <ColorSchemeScript />
      </head>
      <body className="h-screen">
        <MantineProvider>
          <NavigationProgress />
          <AuthenticityTokenProvider token={csrf}>
            <QueryClientProvider client={queryClient}>
              <Notifications position="top-center" />
              <Outlet />
              <ScrollRestoration />
              <Scripts />
            </QueryClientProvider>
          </AuthenticityTokenProvider>
        </MantineProvider>
      </body>
    </html>
  );
}
